<script setup lang="ts">
import { loginValidation } from "~/validation/auth/loginValidation"
import { Field, useForm } from "vee-validate"
import { toTypedSchema } from "@vee-validate/yup"
import type { InferType } from "yup"
import { useAuthStore } from "~/auth/stores/auth"

definePageMeta({
  layout: "auth",
  auth: { unauthenticatedOnly: true, navigateAuthenticatedTo: "/" },
})
const { signIn, status } = useAuth()

const device = useDevice()
const token = ref()
const toast = useToaster()
const auth = useAuthStore()
const company = useCookie("company")
type FormInput = InferType<typeof loginValidation>
const initialValues: FormInput = reactive({
  email: "",
  password: "",
  location: "home",
  remember: false,
  user_agent: device.userAgent,
  token: "",
})
const validationSchema = toTypedSchema(loginValidation)
const { handleSubmit, isSubmitting } = useForm({
  validationSchema,
  initialValues,
})
//On Submit
const onSubmit = handleSubmit(async (values: any) => {
  values["token"] = token.value
  await signIn(values, {
    redirect: false,
  })
    .then(() => {
      if (status.value === "authenticated") {
        toast.show({
          title: "success",
          message: "Successfully login, page will redirect to dashboard",
          color: "success",
          closable: true,
          icon: "lucide:check",
        })
      }
    })
    .then(() => {
      navigateTo("/dashboard", { external: true })
    })
    .catch(() => {
      toast.show({
        title: "Error",
        message: "email or password does not match",
        color: "danger",
        closable: true,
        icon: "ep:warning",
      })
    })
})

const locations = [
  { value: "office", label: "OFFICE", subtitle: "Work from office" },
  { value: "home", label: "HOME", subtitle: "Work from home" },
]
</script>

<template>
  <div>
    <BaseHeading as="h2" size="3xl" lead="relaxed" weight="medium" class="mt-6">
      Welcome back.
    </BaseHeading>
    <BaseParagraph size="sm" class="text-muted-400 mb-6">
      Login with your credentials
    </BaseParagraph>
  </div>
  <div class="mt-6">
    <div class="mt-5">
      <form
        method="POST"
        action=""
        @submit.prevent="onSubmit"
        class="mt-6"
        novalidate
      >
        <div class="space-y-2">
          <div class="grid gap-6 sm:grid-cols-2 mb-5 h-full">
            <Field v-slot="{ field }" name="location">
              <template v-for="l in locations">
                <BaseRadioHeadless
                  v-bind="field"
                  :value="l.value"
                  :model-value="field.value"
                >
                  <BaseCard
                    rounded="lg"
                    class="peer-checked:!border-success-500 peer-checked:!bg-success-500/10 relative border-2 p-5 peer-checked:[&_.child]:!opacity-100"
                  >
                    <div class="flex flex-col">
                      <h4
                        class="text-muted-500 dark:text-muted-200 font-sans text-sm font-medium uppercase leading-tight"
                      >
                        {{ l.label }}
                      </h4>
                      <div class="font-sans">
                        <span
                          class="text-muted-800 dark:text-muted-100 text-sm font-medium"
                          >{{ l.subtitle }}</span
                        >
                      </div>
                    </div>

                    <div class="child absolute end-2 top-4 opacity-0">
                      <Icon
                        name="ph:check-circle-duotone"
                        class="text-success-500 size-7"
                      />
                    </div>
                  </BaseCard>
                </BaseRadioHeadless>
              </template>
            </Field>
          </div>

          <UInput name="email" type="email" label="E-Mail Address" />

          <UInputPassword
            label="Password"
            name="password"
            id="password"
            :show-score="false"
          />
          <NuxtTurnstile v-model="token" />
        </div>

        
        <div class="mt-6 flex items-center justify-between">
          <Field v-slot="{ field, handleChange, handleBlur }" name="remember">
            <BaseCheckbox
              :model-value="field.value"
              :disabled="isSubmitting"
              shape="curved"
              id="remember-me"
              label="Remember Me"
              color="primary"
              @update:model-value="handleChange"
              @blur="handleBlur"
            />
          </Field>

          <div class="text-xs leading-5">
            <NuxtLink
              to="/auth/forgot-password"
              class="text-primary-600 hover:text-primary-500 font-sans font-medium underline-offset-4 transition duration-150 ease-in-out hover:underline"
            >
              Forgot your password?
            </NuxtLink>
          </div>
        </div>

        
        <div class="mt-6">
          <div class="block w-full rounded-md shadow-sm">
            <BaseButton
              :disabled="isSubmitting"
              :loading="isSubmitting"
              type="submit"
              color="primary"
              shape="curved"
              class="!h-11 w-full"
            >
              Sign in
            </BaseButton>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped></style>
